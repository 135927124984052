import React from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO"
import WeddingDate from "../components/wedding-date"

export default () => {
  return (
    <Layout>
      <Seo />
      <h1>RSVP</h1>
      <WeddingDate />
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdzv2CYjQC9FLlxEf5V_hAWH9aTupHey5gt0sPH7lUeNlq66w/viewform?embedded=true" width="100%" height="330" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </Layout>
  )
}

